import * as React from 'react'
import { EmployeeComponent } from './EmployeeComponent'

interface Employee {
  photo: string
  name: string
  mail: string
  orcid: string
  phone: string
  place: string
  position1: string
  position2: string
  title: string
}

interface Props {
  employees: Array<Employee>
  isEn?: boolean
}
export const EmployeesComponent = ({ employees, isEn }: Props) => {
  return (
    <div>
      {employees.map((employee: Employee) => (
        <EmployeeComponent
          employee={employee}
          isEn={isEn}
        />
      ))}
    </div>
  )
}
