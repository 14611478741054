import { makeStyles } from '@material-ui/core'
import * as React from 'react'

const useStyles = makeStyles({
  picture: {
    width: '250px',
    height: '250px',
    objectFit: 'cover',
    ['@media(max-width: 1010px)']: {
      width: '80vw',
      height: '80vw',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '20px'
    }
  },
  wrapper: {
    display: 'flex',
    padding: '20px',
    ['@media(max-width: 1010px)']: {
      display: 'block',
      width: '100%',
      margin: 'auto'
    }
  },
  description: {
    marginLeft: '10px',
    ['@media(max-width: 1010px)']: {
      margin: '0',
      textAlign: 'center'
    }
  },
  name: {
    fontWeight: 800,
    fontSize: '26px'
  },
  position: {
    fontWeight: 500,
    fontSize: '20px',
    paddingBottom: '10px'
  },
  secondLine: {
    fontSize: '24px',
    fontWeight: 500
  }
})
interface Employee {
  photo: string
  name: string
  mail: string
  orcid: string
  phone: string
  place: string
  position1: string
  position2: string
  title: string
}

interface Props {
  employee: Employee
  isEn?: boolean
}

const getTitle = (title: string) => {
  return title !== null ? title : ''
}
export const EmployeeComponent = ({ employee, isEn }: Props) => {
  const styles = useStyles()
  const {
    title,
    name,
    position1,
    position2,
    place,
    phone,
    mail
  } = employee
  const secondLine = position2 !== null ? position2 : ''
  const firstLine = getTitle(title) + ' ' + name
  const roomText = isEn ? 'Room: ' : 'Pokój: '
  const phoneText = isEn ? 'Phone: ' : 'Telefon: '
  const emailText = 'e-mail: '
  return (
    <div className={styles.wrapper}>
      <div className={styles.description}>
        <div className={styles.name}>{firstLine}</div>
        <div className={styles.secondLine}>{secondLine}</div>
        <div className={styles.position}>{position1}</div>
        <div>{roomText + place}</div>
        <div>{phoneText + phone}</div>
        <div>{emailText + mail}</div>
      </div>
    </div>
  )
}
